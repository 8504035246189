class Signup {
  hook () {
    this.hideUsername();
    this.checkHumanInput();
  }

  hideUsername () {
    // Spambots often don't have JS support.
    // They will attempt to fill every field on a form.
    // We have a fake username field. We hide it for humans.
    // A spambot will submit it.
    const userShouldNotFillThis = document.querySelector('.usnft');
    userShouldNotFillThis.style.display = 'none';
  }

  checkHumanInput () {
    // Spambots often don't have mouse support,
    // or will not know to trigger a mouse mouve event.
    // Since we don't autofocus on the first field,
    // user will have to use the 'tab' key or use the mouse.
    const humanInputWasMade = document.querySelector('#hiwm');

    document.addEventListener('mousemove', () => {
      if (humanInputWasMade.value !== 'yes')
        humanInputWasMade.value = 'yes';
    });

    document.addEventListener('keyup', (event) => {
      if (event.which === 9)
        humanInputWasMade.value = 'yes';
    });
  }

  static ready () {
    const newUserForm = document.querySelector('form#new_user');
    if (newUserForm)
      new Signup().hook();
    ;
  }
}

export default Signup;
