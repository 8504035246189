<template>
  <div v-show="selectedTitle === title">
    <slot></slot>
  </div>
</template>

<script>
export default {
  inject: ['selectedTitle'],
  props: ['title'],
  name: 'tab-item'
};
</script>
